import React from "react"
import { Button, Result, Typography } from "antd"
import { Layout } from "../components/Layout/Layout"
import { SinglePage } from "../components/SinglePage/SinglePage"
import Link from "next/link"
import { NextSeo } from "next-seo"
import { webUrl } from "../config/web"
import { CommonArticles } from "../components/blog/common"
import { CoursesList } from "../components/CoursesList/CoursesList"
import { css } from "@emotion/react"
import { GetStaticProps, GetStaticPropsResult } from "next"
import { AllProduct, getAllProducts } from "../lib/contentful/product/getAllProducts"
import { sortSimilarProducts } from "../lib/contentful/sortSimilarProducts"
import { dev } from "../lib/dev"
import { getAvailableAndFilterOutProducts } from "../lib/contentful/product/getAvailableProducts"
import { useRouter } from "next/router"

const getLastSegment = (url: string): string | undefined => url.split("/").pop() || undefined

const Custom404 = ({ allContentfulProducts }: Props) => {
  const router = useRouter()

  const slug = getLastSegment(router.asPath)
  const allAvailableContentfulProducts = sortSimilarProducts(getAvailableAndFilterOutProducts(undefined, allContentfulProducts), slug)

  return (
    <>
      <NextSeo
        title="Nenalezeno"
        description="Litujeme, stránka, kterou jste navštívili, neexistuje. Pokud obtíže přetrvávají, kontaktujte nás, prosím. Děkujeme!"
        canonical={webUrl}
      />

      <Layout>
        <SinglePage title="Nenalezeno" color={undefined} bgImage={undefined}>
          <Result
            status="404"
            title="404"
            subTitle="Litujeme, stránka, kterou jste navštívili, neexistuje. Pokud obtíže přetrvávají, kontaktujte nás, prosím. Děkujeme!"
            extra={
              allAvailableContentfulProducts?.length > 0 ? undefined : (
                <Link href="/">
                  <Button type="primary">Zpět na hlavní stránku</Button>
                </Link>
              )
            }
          />

          <section>
            {allAvailableContentfulProducts?.length > 0 && (
              <CommonArticles>
                <Typography.Title level={2}>Možná jste měli na mysli ...</Typography.Title>

                <CoursesList
                  courses={allAvailableContentfulProducts}
                  productsColors={allContentfulProducts?.productsColors}
                  informationOnly={true}
                  css={css`
                    margin: 20px 0 0 0;
                  `}
                />
              </CommonArticles>
            )}
          </section>
        </SinglePage>
      </Layout>
    </>
  )
}

interface Props {
  allContentfulProducts: AllProduct | null
}

export const getStaticProps: GetStaticProps = async (): Promise<GetStaticPropsResult<Props>> => {
  const allContentfulProducts = await getAllProducts()

  return {
    props: {
      allContentfulProducts
    },
    revalidate: dev ? 1 : 120
  }
}

export default Custom404
