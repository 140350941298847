import leven from "leven"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton } from "./ContentfulVzdelanibudoucnostiTypes"

const tokenizeSlug = (slug: string): string[] => {
  return slug
    .toLowerCase()
    .split("-")
    .filter((token) => token.length > 2)
}

const compareTags = (targetTags: string[], courseTags: string[]): number => {
  const distances = targetTags.map((targetTag) => {
    const tagDistances = courseTags.map((courseTag) => leven(targetTag, courseTag))
    return Math.min(...tagDistances)
  })

  return distances.reduce((sum, dist) => sum + dist, 0)
}

export const sortSimilarProducts = (
  courses: Entry<TypeProductEntitySkeleton, undefined, string>[],
  targetSlug?: string
): Entry<TypeProductEntitySkeleton, undefined, string>[] => {
  if (!targetSlug) {
    return courses
  }

  const tagsFromSlug = tokenizeSlug(targetSlug)

  return courses
    .map((p) => {
      const courseTags = tokenizeSlug(p.fields.slug)
      const distance = compareTags(tagsFromSlug, courseTags)

      return { product: p, distance }
    })
    .sort((a, b) => a.distance - b.distance)
    .map((p) => p.product)
}
